<template>
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
  
        <!-- Brand logo-->
        <b-link class="brand-logo">
          <abracadalo-logo />
        </b-link>
        <!-- /Brand logo-->
  
        <!-- Left Text-->
        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              fluid
              :src="imgUrl"
              alt="Login V2"
            />
          </div>
        </b-col>
        <!-- /Left Text-->
  
        <!-- Login-->
        <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <div v-if="done">
                <b-card-text class="mb-2">
                    <b-card-title
                        title-tag="h2"
                        class="font-weight-bold mb-1"
                    >
                        You've got mail! 📬
                    </b-card-title>
                    If an account with the give email address exists, you should have received an email containing a link.
                    Click on the link and set a new password for yourself.
                    Afterwards you should be able to sign in with your new password.
                </b-card-text>
                <b-link :to="{name:'login'}">
                    <b-button
                        type="submit"
                        variant="primary"
                        block
                    >Back to Login</b-button>
                  </b-link>
            </div>
            <div v-else>
                <b-card-title
                    title-tag="h2"
                    class="font-weight-bold mb-1"
                >
                    Reset Password
                </b-card-title>
                <b-card-text class="mb-2">
                    Forgot your password, eh? It happens. Enter the email address associated with your Abracadalo account to request a password reset link.
                </b-card-text>
    
                <validation-observer ref="loginValidation">
                <b-form
                    class="auth-login-form mt-2"
                    @submit.prevent
                >
                    <b-form-group
                    label="Email"
                    label-for="login-email"
                    >
                    <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email"
                    >
                        <b-form-input
                        id="login-email"
                        v-model="userEmail"
                        :state="errors.length > 0 ? false:null"
                        name="login-email"
                        placeholder="john@example.com"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
    
                    <b-button
                        type="submit"
                        variant="primary"
                        block
                        @click="sendPasswordResetLink"
                    >
                        Send Password Reset Link
                    </b-button>
                </b-form>
                </validation-observer>
            </div>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </template>
  
  <script>
  /* eslint-disable global-require */
  import { BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend,
      BInputGroup,
      BFormCheckbox,
      BCardText,
      BCardTitle,
      BImg,
      BForm,
      BButton } from 'bootstrap-vue'
  import { getAuth, sendPasswordResetEmail } from "firebase/auth";
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import AbracadaloLogo from '@core/layouts/components/Logo.vue'
  import { required, email } from '@validations'
  import { togglePasswordVisibility } from '@core/mixins/ui/forms'
  import store from '@/store/index'
  import SocialLogin from "./components/SocialLogin.vue"
  
  export default {
    components: {
      BRow,
      BCol,
      BLink,
      BFormGroup,
      BFormInput,
      BInputGroupAppend,
      BInputGroup,
      BFormCheckbox,
      BCardText,
      BCardTitle,
      BImg,
      BForm,
      BButton,
      AbracadaloLogo,
      ValidationProvider,
      ValidationObserver,
          SocialLogin
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        userEmail: '',
        sideImg: require('@/assets/images/pages/login-v2.svg'),
        required,
        email,
        done: false
      }
    },
    computed: {
      imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
          return this.sideImg
        }
        return this.sideImg
      },
    },
    methods: {
        sendPasswordResetLink() {
            this.$refs.loginValidation.validate().then(success => {
            if (!success) return;
            sendPasswordResetEmail(getAuth(), this.userEmail)
                .catch(console.error)
                .finally(() => {
                    this.done = true;
                })
            })
        },
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  </style>
  